<template>
  <div id="login-page" class="pt-9">
    <app-box width="small">
      <template #legend>Log in to your account</template>
      <transition name="fade">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <app-form-alert v-if="form.error || form.success" :type="form.success ? 'success' : 'error'" v-html="form.success ? form.success : form.error" />
      </transition>
      <app-form @submit.prevent="submitForm">
        <app-form-field>
          <app-label id="email">
            Email address
          </app-label>
          <app-input id="email" v-model.trim="form.fields.email" autocomplete="email" />
        </app-form-field>
        <app-form-field>
          <app-label id="password">
            Password
          </app-label>
          <app-input id="password" v-model="form.fields.password" type="password" autocomplete="current-password" />
        </app-form-field>
        <app-button class="mt-3 transition-colors" :disabled="loading || requiredFieldIsBlank">
          Log in
        </app-button>
      </app-form>
      <hr class="mt-8 mb-2 border-gray-300">
      <div class="flex">
        <p class="w-1/2 mr-2 text-xs text-gray-600">
          Don't have an account?<br>
          <router-link to="/signup" class="text-blue-600 underline hover:no-underline">
            Click here to get one.
          </router-link>
        </p>
        <p class="w-1/2 ml-2 text-xs text-gray-600">
          Forgot your password?<br>
          <router-link to="/reset" class="text-blue-600 underline hover:no-underline">
            Click here to reset it.
          </router-link>
        </p>
      </div>
    </app-box>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "Login",
  data: () => ({
    form: {
      fields: {
        email: "",
        password: "",
      },
      error: "",
      success: "",
    },
  }),
  computed: {
    ...mapState(["loading"]),
    requiredFieldIsBlank() {
      const { email, password } = this.form.fields;
      return email === "" || password === "";
    },
  },
  created() {
    if (this.$route.query.reset) {
      this.form.success = `Ok, your password has been updated.<br><span class="font-normal">Go ahead and use it to log in below.</span>`;
    }
  },
  methods: {
    async submitForm() {
      if (await U.getToken({ ...this.form.fields })) {
        this.$store.dispatch("initAccount");
        this.$router.push("/projects");
      } else {
        this.form.error = `Sorry, login failed.<br><span class="font-normal">Make sure you're using the correct email and password and give it a try again.</span>`;
      }
    },
  },
};
</script>
